/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../assets/colors';
import TopBar from './subComponents/top-bar';
import RightSideBar from './subComponents/right-sidebar';
import { fabric } from 'fabric';
import { Canvas, IEvent } from 'fabric/fabric-impl';
import ToolBar from '../common/floating-tool-bar';
import {
  DataToGenerateAngleImage,
  JSONObject,
  ObjectTypes,
  ToolBarProps,
  UndoRedoConfig,
} from '../../interface';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getObjects,
  addObject,
  deleteObject,
  setObjects,
  updateObject,
  setSelectedColors,
  getSelectedColors,
  setUpdatingJson,
  getEditorData,
  setBase64Cache,
  setIsBlobUpdating,
  setEditorData,
} from '../../store/slices/editor';
import {
  applyClippingToCanvas,
  findVariantByVariantIdAndConnectedVariants,
  getCurrentAngleJson,
  getCurrentViewImage,
  initializeFabricWithNewCanvas,
  onDropRejectedHandler,
  uploadToGallery,
} from './utils';
import { fabricFieldsToKeep } from '../common/contants';
import { QuestionMarkIcon } from '../../assets/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetSingleCatalogProductForEditorQuery } from '../../store/services/catalog';
import Loader from '../common/loader';
import { toast } from 'react-toastify';
import { useLazyGetPersonalizationDataByOrderIdQuery } from '../../store/services/orders';
import { useGetRawProductDetailsQuery } from '../../store/services/products';
import { useAddImageToGalleryMutation } from '../../store/services/gallery';
import * as PIXI from 'pixi.js';
import UndoRedoHandler from './subComponents/undo-redo';
import { debounce } from 'lodash';
import KeyboardShortcutModal from './subComponents/keyboard-shortcut';
import ApparelEditor from './subComponents/apparel';
import MugsEditor from './subComponents/mugs';
import OrnamentEditor from './subComponents/ornaments';
import {
  VariantEditorDataV2,
  VariantsV2,
} from '../../interface/catalog-interface-v2';
import { loadImagesWithWorker } from '../mockups/workers/preload-images';
import { imageWorkerCallBack } from '../mockups/utils';
import { routeNames } from '../../constants/routesPath';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { MAX_DESIGN_FILE_SIZE } from '../../constants/designTabs';
import { downscaleImage } from '../../classes/image';
import { getOrnamentsModel } from './subComponents/ornaments/utils';
import { getUtilityData } from '../../store/slices/utilitySlice';
import { ProductName, ProductType } from '../../interface/product-interface';
import DropzoneOverlay from '../common/dropzone-overlay';

interface EditorProps {
  isTablet: boolean;
  isSellerEditor: boolean;
  ordersCatalogId?: string;
  ordersPrintProviderId?: string;
  productIdFromPersonalization?: string;
  transactionId?: number;
  variantIdFromPeronalization?: string;
  instructions?: string;
  editorDataFromProps?: any;
  setShowPersonalizeEditorModal?: (value: any) => void;
}
const Editor: React.FC<EditorProps> = ({
  isTablet,
  isSellerEditor = true,
  productIdFromPersonalization,
  transactionId,
  ordersPrintProviderId,
  ordersCatalogId,
  variantIdFromPeronalization,
  instructions,
  editorDataFromProps,
  setShowPersonalizeEditorModal,
}) => {
  fabric.Object.NUM_FRACTION_DIGITS = 10;
  const { currentTab, base64Cache, updatedEditorData } =
    useAppSelector(getEditorData);

  const base64CacheRef = useRef<Record<string, string>>({});
  const dropzoneRef = useRef<DropzoneRef | null>(null);
  const controllerRef = useRef<AbortController | null>(null);
  const [preloadedImages, setPreloadedImages] = useState<{
    [key: string]: string;
  }>({});
  const [addImageLoader, setAddImageLoader] = useState<boolean>(false);
  const undoAndRedoLoadingRef = useRef(false);
  const isKeyLongPressed = useRef<boolean>(false);
  const [pixiApp, setPixiApp] = useState<
    PIXI.Application<PIXI.ICanvas> | undefined
  >();
  const [isAnglesLoading, setIsAnglesLoading] = useState<boolean>(false);
  const [drawingBoard, setDrawingBoard] = useState<Canvas>();
  const [keyboardModal, setKeyboardModal] = useState<boolean>(false);
  const [selectedElementProperties, setSelectedElementProprties] =
    useState<ToolBarProps>({ type: '', top: 0, left: 0, id: '' });
  const [editorDataPropsJson, setEditorDataFromPropsJson] = useState('');

  const [selectedVariant, setSelectedVariant] = useState<VariantsV2>();

  const [orderInfoForPersonalization, setOrderInfoForPersonalization] =
    useState('');
  const [selectedAngle, setSelectedAngle] = useState<{
    angle: string;
    image: string;
    originalImage: any;
  }>({ angle: 'front', image: '', originalImage: '' });
  const [configForUndoRedo, setConfigForUndoRedo] = useState<UndoRedoConfig>({
    [selectedAngle.angle]: [],
    [selectedAngle.angle + 'CurrentStateIndex']: -1,
    undoStatus: false,
    redoStatus: false,
    undoFinishedStatus: 1,
    redoFinishedStatus: 1,
    specificDesign: {},
  });
  const { isSidebarExpanded } = useAppSelector(getUtilityData);
  const [moving, setMoving] = useState(false);
  const files = useAppSelector(getObjects);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [getOrderData, { isFetching }] =
    useLazyGetPersonalizationDataByOrderIdQuery();
  const selectedColors = useAppSelector(getSelectedColors);
  const currentColor = useRef('');
  const [variantEditorData, setVariantEditorData] = useState<
    VariantEditorDataV2[]
  >([]);
  const [defaultEditorData, setDefaultEditorData] = useState<any>({
    front: null,
    back: null,
  });
  const stateValuesRef = useRef<any>({
    defaultEditorData: defaultEditorData,
    variantEditorData: variantEditorData,
  });
  stateValuesRef.current.defaultEditorData = defaultEditorData;
  stateValuesRef.current.variantEditorData = variantEditorData;
  const [updating, setUpdating] = useState<boolean>(false);
  const [callBuildImages, setCallBuildImages] = useState(false);

  const loadingRef = useRef(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const mugCanvasRef = useRef<HTMLDivElement>(null);
  const ornamentCanvasRef = useRef<HTMLDivElement>(null);
  const apparelCanvasRef = useRef<HTMLDivElement>(null);
  const toteBagCanvasRef = useRef<HTMLDivElement>(null);
  const [designChanged, setDesignChanged] = useState(false);
  const navigate = useNavigate();
  const { productId, printProviderId, catalogProductId } = useParams();
  const [addToGallery, { isLoading: isUploading }] =
    useAddImageToGalleryMutation();
  const {
    data,
    isLoading: isFetchingCatalogInfo,
    isSuccess,
  } = useGetSingleCatalogProductForEditorQuery(
    {
      catalogProductId: ordersCatalogId || catalogProductId,
      printProviderId: ordersPrintProviderId ?? printProviderId,
      fromEditor: 'true',
    },
    {
      skip:
        (ordersCatalogId || catalogProductId) &&
        (ordersPrintProviderId || printProviderId)
          ? false
          : true,
    },
  );
  const catalogData = data?.catalogProductInfo;
  const catalogVariants = data?.variants;
  const printAreas = catalogData?.printAreas.find(
    (printArea) =>
      printArea.printProviderId === (ordersPrintProviderId ?? printProviderId),
  );
  const printSides = printAreas?.sides.map((e) => e.side) || [];
  const [dataToGenerateAngleImage, setDataToGenerateAngleImages] = useState<
    DataToGenerateAngleImage[]
  >([]);

  const {
    data: productInfo,
    isFetching: isProductInfoFetching,
    isSuccess: isProductInfoSuccess,
  } = useGetRawProductDetailsQuery(
    {
      productId: productId || productIdFromPersonalization,
    },
    {
      skip:
        isSuccess && (productId || productIdFromPersonalization) ? false : true,
    },
  );
  const productVariants = productInfo?.data.variants;
  const currentProductType = catalogData?.editorType;
  const isColoredMug = catalogData?.editorSubType === ProductName.ColoredMug;
  const isBlackMug = catalogData?.editorSubType === ProductName.BlackMug;
  const div = document.getElementById('drawingArea');
  useEffect(() => {
    if (div && !div.className.includes('for-extra-flow')) {
      const canvas: Canvas = new fabric.Canvas('drawingArea', {
        preserveObjectStacking: true,
        centeredScaling: true,
        controlsAboveOverlay: true,
      });
      applyClippingToCanvas(
        canvas,
        catalogData,
        undefined,
        selectedAngle.angle,
        currentColor.current === ''
          ? selectedVariant?.colorHex
          : currentColor.current,
      );
      setDrawingBoard(canvas);
    }
  }, [div]);

  useEffect(() => {
    base64CacheRef.current = base64Cache;
  }, [base64Cache]);

  /*** Start Update Undo Redo  */

  const updateUndoRedoCanvasState = () => {
    const variantSpecificDesign = variantEditorData.find(
      (variant) => variant.id === selectedVariant?.id,
    );

    const getCanvasAsJson: any = () => {
      const jsonData = drawingBoard?.toJSON(fabricFieldsToKeep);

      const finalObjects = {
        ...jsonData,
        objects: jsonData?.objects.filter(
          (obj) =>
            obj.type === ObjectTypes.image || obj.type === ObjectTypes.text,
        ),
      };

      return JSON.stringify(finalObjects);
    };
    const updateConfigForSpecificDesign = () => {
      if (variantSpecificDesign?.variantSpecificDesign) {
        setConfigForUndoRedo((prevConfig) => {
          let newConfig = { ...prevConfig };
          const prevSpecificDesign: any = prevConfig.specificDesign;
          const currentConfig = prevSpecificDesign[variantSpecificDesign.id]?.[
            selectedAngle.angle
          ] ?? [
            ...(prevConfig[selectedAngle.angle] ?? []), // Added previous steps into specific design
          ];
          const indexToBeInsertedForSpecificDesign =
            (prevSpecificDesign[variantSpecificDesign.id]?.[
              selectedAngle.angle + 'CurrentStateIndex'
            ] ??
              prevConfig[selectedAngle.angle + 'CurrentStateIndex'] ??
              -1) + 1;

          const slicedConfig = currentConfig.slice(
            0,
            indexToBeInsertedForSpecificDesign,
          );

          newConfig = {
            ...prevConfig,
            specificDesign: {
              ...prevSpecificDesign,
              [variantSpecificDesign.id]: {
                ...prevSpecificDesign[variantSpecificDesign.id],
                [selectedAngle.angle]: [...slicedConfig, getCanvasAsJson()],
                [selectedAngle.angle + 'CurrentStateIndex']:
                  indexToBeInsertedForSpecificDesign,
              },
            },
          };
          return newConfig;
        });
      }
    };

    const updateConfigForCanvasState = () => {
      setConfigForUndoRedo((prevConfig) => {
        let newConfig = { ...prevConfig };
        const indexToBeInserted =
          (prevConfig[selectedAngle.angle + 'CurrentStateIndex'] ?? -1) + 1;
        newConfig = {
          ...prevConfig,
          [selectedAngle.angle]: [
            ...(prevConfig[selectedAngle.angle] ?? [])?.slice(
              0,
              indexToBeInserted,
            ),
            getCanvasAsJson(),
          ],
          [selectedAngle.angle + 'CurrentStateIndex']: indexToBeInserted,
        };
        return newConfig;
      });
    };

    if (variantSpecificDesign?.variantSpecificDesign) {
      updateConfigForSpecificDesign();
    } else {
      updateConfigForCanvasState();
    }
  };

  /** End Update Undo Redo */

  useEffect(() => {
    if (isProductInfoSuccess) {
      try {
        if (editorDataFromProps) {
          setEditorDataFromPropsJson(editorDataFromProps);
          updateEditorData(editorDataFromProps);
          setDefaultEditorData(editorDataFromProps.defaultEditorData);
          setVariantEditorData(editorDataFromProps.variantEditorData);
        } else if (!editorDataFromProps && productInfo?.data?.editorData) {
          const editorData = JSON.parse(productInfo.data.editorData);
          setDefaultEditorData(editorData.defaultEditorData);
          setVariantEditorData(editorData.variantEditorData);
          setEditorDataFromPropsJson(editorData);
          updateEditorData(editorData);
          dispatch(setEditorData(editorData));
        }
      } catch (error) {
        console.error(error);
        toast.error('Something is wrong with this product!!');
        setTimeout(() => {
          navigate(routeNames.myProducts);
        }, 1500);
      }
    }
  }, [isProductInfoSuccess, productInfo]);

  const updateEditorData = (editorData: {
    variantEditorData: any[];
    defaultEditorData: any;
  }) => {
    if (isProductInfoSuccess && productVariants && catalogVariants) {
      const selectingVariant = variantIdFromPeronalization
        ? findVariantByVariantIdAndConnectedVariants(
            catalogVariants,
            variantIdFromPeronalization,
          )
        : findVariantByVariantIdAndConnectedVariants(
            catalogVariants,
            productVariants[0].id,
          );
      const selectedVariants: VariantsV2[] = [];
      for (const variant of editorData.variantEditorData) {
        if (catalogVariants) {
          const index = catalogVariants.findIndex(
            (e) =>
              e.id === variant.id ||
              e.connectedVariants.map((a) => a.id).includes(variant.id),
          );
          if (catalogVariants[index]) {
            selectedVariants.push(catalogVariants[index]);
          }
        }
      }
      let editorDataJson: VariantEditorDataV2 | undefined =
        editorData.variantEditorData.find(
          (variant: VariantEditorDataV2) =>
            variant.id === selectingVariant?.id ||
            selectingVariant?.connectedVariants.some(
              (e: { id: string }) => e.id === variant.id,
            ),
        );
      if (editorDataJson?.variantSpecificDesign) {
        editorDataJson = editorDataJson?.front;
      } else {
        editorDataJson = editorData.defaultEditorData.front;
      }
      setSelectedVariant(selectingVariant);
      if (currentProductType === ProductType.Ornament) {
        setSelectedAngle({
          angle: 'front',
          image:
            catalogData?.previewBoundaryBox.normalfrontMainImageUrl.toString() ??
            '',
          originalImage:
            catalogData?.previewBoundaryBox.normalfrontMainImageUrl.toString() ??
            '',
        });
      } else {
        setSelectedAngle({
          angle: 'front',
          image: selectingVariant?.images['front'] ?? '',
          originalImage: selectingVariant?.images['front'] ?? '',
        });
      }
      loadingRef.current = true;
      initializeFabricWithNewCanvas(
        catalogData,
        initializeCanvasWithJson,
        editorDataJson,
        true,
        printAreas,
        selectingVariant,
        undefined,
        undefined,
        undefined,
        currentProductType === ProductType.Ornament
          ? selectingVariant?.colorHex
          : undefined,
      );
      dispatch(
        setSelectedColors({
          data: selectedVariants,
        }),
      );
    }
  };

  useEffect(() => {
    const updatedVariantData: VariantEditorDataV2[] = [];
    const variants: VariantEditorDataV2[] =
      stateValuesRef.current.variantEditorData;
    for (const variant of selectedColors) {
      const index = variants.findIndex((color: any) => color.id === variant.id);
      if (index >= 0) {
        updatedVariantData.push(variants[index]);
      } else {
        updatedVariantData.push({
          front: null,
          back: null,
          variantSpecificDesign: false,
          id: variant.id,
          label: variant.label,
        });
      }
    }
    if (
      updatedVariantData.length === 1 &&
      updatedVariantData[0].variantSpecificDesign === true
    ) {
      setDefaultEditorData({
        front: updatedVariantData[0].front,
        back: updatedVariantData[0].back,
        frontimage: updatedVariantData[0].frontimage,
        backimage: updatedVariantData[0].backimage,
      });
      const varData = {
        ...updatedVariantData[0],
        front: null,
        back: null,
        frontimage: null,
        backimage: null,
        variantSpecificDesign: false,
      };
      updatedVariantData[0] = varData;
    }
    setVariantEditorData(updatedVariantData);
  }, [selectedColors]);

  /** callback func for web worker */
  const workerCallBack = (data: { [key: string]: Blob }, err: any) => {
    imageWorkerCallBack(data, err, setPreloadedImages);
  };

  useEffect(() => {
    if (catalogVariants) {
      const variant =
        catalogVariants.find((variant: any) => variant.label === 'White') ??
        catalogVariants[0];

      if (currentProductType !== ProductType.Mug) {
        let allImagesUrls: string[] = [];
        if (currentProductType === ProductType.Ornament) {
          allImagesUrls = [
            catalogData?.previewBoundaryBox[
              'normalfrontFullAlphaImageUrl'
            ] as string,
            catalogData?.previewBoundaryBox[
              'normalfrontMainImageUrl'
            ] as string,
          ];
        } else {
          allImagesUrls = catalogVariants.flatMap((obj) =>
            Object.values(obj.images).filter(
              (image) =>
                typeof image === 'string' && image.includes('https://'),
            ),
          );
        }
        /** calling webworker to preload images */
        loadImagesWithWorker(allImagesUrls, workerCallBack);
      }

      if (catalogData && !productId) {
        setVariantEditorData([
          {
            id: variant.id,
            front: null,
            back: null,
            variantSpecificDesign: false,
            label: variant?.label,
          },
        ]);
        setSelectedVariant(variant);

        if (currentProductType === ProductType.Ornament) {
          setSelectedAngle({
            ...selectedAngle,
            image:
              (catalogData?.previewBoundaryBox[
                'normal' + selectedAngle.angle + 'MainImageUrl'
              ] as string) ?? '',
            originalImage:
              (catalogData?.previewBoundaryBox[
                'normal' + selectedAngle.angle + 'MainImageUrl'
              ] as string) ?? '',
          });
        } else {
          setSelectedAngle({
            ...selectedAngle,
            image: variant.images[selectedAngle.angle],
            originalImage: variant.images[selectedAngle.angle],
          });
        }
        dispatch(
          setSelectedColors({
            data: [variant],
          }),
        );
      }
    }
  }, [catalogData, dispatch]);

  const getVariantSpecificImageData = (variant: VariantsV2 | undefined) => {
    const variantData = variantEditorData.find((v) => v.id === variant?.id);
    if (variantData?.variantSpecificDesign) {
      return variantData?.frontimage;
    } else return defaultEditorData.frontimage;
  };

  const generateMockupsForEachColorAndAngle = async (
    canvas: any,
    loadMockupFromApi: boolean,
    updatedVariants: any,
    json: any,
  ) => {
    try {
      let variantEditorDataCopy = variantEditorData;
      let defaultEditorDataCopy = defaultEditorData;

      if (loadMockupFromApi) {
        if (editorDataFromProps) {
          variantEditorDataCopy = editorDataFromProps.variantEditorData;
          defaultEditorDataCopy = editorDataFromProps.defaultEditorData;
        } else {
          const editorData = JSON.parse(productInfo?.data.editorData);
          variantEditorDataCopy = editorData.variantEditorData;
          defaultEditorDataCopy = editorData.defaultEditorData;
        }
      }

      const variantToUse = variantIdFromPeronalization
        ? [
            findVariantByVariantIdAndConnectedVariants(
              updatedVariants,
              variantIdFromPeronalization,
            ),
          ]
        : updatedVariants;

      const dataForAngleImages = [];

      for (const [loopIndex, updatedVariant] of variantToUse.entries()) {
        for (const angle of printSides) {
          let imageData: any = '';
          const index = variantEditorDataCopy.findIndex(
            (e) =>
              e.id === updatedVariant.id ||
              ((updatedVariant.connectedVariants || []) as any)
                .map((a: { id: any }) => a.id)
                .includes(e.id),
          );

          const newVariant: any = variantEditorDataCopy[index];

          try {
            if (
              newVariant?.variantSpecificDesign &&
              newVariant[angle] !== null
            ) {
              if (newVariant[angle + 'image']) {
                imageData = newVariant[angle + 'image'];
              } else {
                imageData = await getCurrentViewImage(
                  canvas,
                  catalogData,
                  angle,
                  newVariant[angle],
                  currentProductType,
                  undefined,
                  printAreas,
                );
                imageData = imageData.blob;
                newVariant[angle + 'image'] = imageData;
              }
            } else if (
              defaultEditorDataCopy[angle] !== null &&
              defaultEditorDataCopy[angle] !== ''
            ) {
              if (defaultEditorDataCopy[angle + 'image']) {
                imageData = defaultEditorDataCopy[angle + 'image'];
              } else {
                imageData = await getCurrentViewImage(
                  canvas,
                  catalogData,
                  angle,
                  defaultEditorDataCopy[angle],
                  currentProductType,
                  undefined,
                  printAreas,
                );
                imageData = imageData.blob;
                defaultEditorDataCopy[angle + 'image'] = imageData;
              }
            }
            if (loopIndex === 0) {
              const catalogVariant = findVariantByVariantIdAndConnectedVariants(
                catalogVariants,
                updatedVariant.id,
              );
              dataForAngleImages.push({
                imageData,
                catalogData,
                variant: catalogVariant,
                pixiApp,
                angle,
              });
            }
          } catch (error) {
            console.error('Error getting image:', error);
          }
        }
      }

      if (currentProductType !== ProductType.Mug) {
        setDataToGenerateAngleImages(dataForAngleImages);
      }
      if (loadMockupFromApi) {
        setDefaultEditorData(defaultEditorDataCopy);
        setVariantEditorData(variantEditorDataCopy);
      }
    } catch (error) {
      console.error(
        'Unexpected Error in generateMockupsForEachColorAndAngle:',
        error,
      );
    }
  };

  function loadImageFromUrl(
    url: string,
    callback: (base64: string, img: fabric.Image) => void,
  ) {
    fabric.Image.fromURL(
      url,
      async function (img) {
        const downscaledImage = await downscaleImage(
          img.getElement(),
          drawingBoard?.width,
          drawingBoard?.height,
        );

        const tempCanvas = document.createElement('canvas');
        tempCanvas.style.display = 'none';
        const tempCtx = tempCanvas.getContext('2d');

        tempCanvas.width = img.width ?? 0;
        tempCanvas.height = img.height ?? 0;
        tempCtx?.drawImage(
          img.getElement(),
          0,
          0,
          img.width ?? 0,
          img.height ?? 0,
        );
        /** Original Image base64 */
        const base64 = tempCanvas.toDataURL();
        dispatch(setBase64Cache({ blobUrl: downscaledImage, base64 }));

        callback(base64, img);
      },
      { crossOrigin: 'anonymous' },
    );
  }
  // mockups-1
  const initializeCanvasWithJson = async (
    json: any,
    canvas: Canvas | undefined,
    loadMockupFromApi: boolean = false,
    updatedVariant?: VariantsV2,
    updateUndoRedoState?: () => void,
    angle?: string,
    angleChange?: boolean,
    color?: string,
  ) => {
    setSelectedElementProprties({ id: '', top: 0, left: 0, type: '' });

    if (json) {
      const parent = document.getElementById('canvas-parent');
      const e = parent?.firstChild;
      if (e && e instanceof HTMLElement) {
        e.style.display = 'none';
      }
      canvas?.loadFromJSON(
        json,
        async () => {
          dispatch(setObjects(JSON.parse(json)));

          canvas.renderAll.bind(canvas);

          setDrawingBoard(canvas);

          if (loadingRef.current) {
            loadingRef.current = false;
          }
          applyClippingToCanvas(
            canvas,
            catalogData,
            undefined,
            angle,
            color
              ? color
              : currentColor.current === ''
              ? selectedVariant?.colorHex
              : currentColor.current,
          );

          if (undoAndRedoLoadingRef.current) {
            undoAndRedoLoadingRef.current = false;
            await debouncedUpdateBlob(
              defaultEditorData,
              variantEditorData,
              canvas,
            );
          }
          dispatch(setUpdatingJson(false));
        },
        async (obj: any, objCls: any) => {
          if (obj.personalizationData) {
            if (objCls.type === 'custom-text') {
              objCls.on('editing:entered', function () {
                objCls.set({
                  backgroundColor: 'transparent',
                });
                canvas.renderAll();
              });
            }
            if (obj.personalizationData.isLocked === true) {
              objCls.set({
                hasControls: false,
                lockMovementX: true,
                lockMovementY: true,
              });
            }
          }
          if (objCls.type === 'custom-image' && obj.src.startsWith('http')) {
            objCls.set({ visible: true });
            canvas.renderAll();
            await new Promise<void>((loadResolve) => {
              loadImageFromUrl(obj.src, (base64, img) => {
                const height = canvas.getObjects().find((e: any) => e.id === obj.id)?.getScaledHeight();
                const widht = canvas.getObjects().find((e: any) => e.id === obj.id)?.getScaledWidth();
                objCls.setElement(img.getElement());
                objCls.setSrc(base64, () => {
                  objCls.scaleToHeight(height?.toFixed(1));
                  objCls.scaleToWidth(widht?.toFixed(1));
                  if (obj.originalHeight === obj.height) {
                    objCls.set({ scaleX: obj.scaleX, scaleY: obj.scaleY });
                  }
                  objCls.setCoords();
                  canvas.requestRenderAll();
                  // Resolve the promise here after all operations are done
                  loadResolve();
                });
              });
            });
          }
          // generating mockups section
          if (updatedVariant && !angleChange) {
            await generateMockupsForEachColorAndAngle(
              canvas,
              loadMockupFromApi,
              loadMockupFromApi
                ? productInfo
                  ? productVariants
                  : []
                : [updatedVariant ?? selectedVariant],
              json,
            );
            setUpdating(false);
          }
        },
      );
    } else {
      canvas?.loadFromJSON('{"objects":[]}', async () => {
        canvas.renderAll.bind(canvas);
        setDrawingBoard(canvas);
        dispatch(setObjects({ objects: [] }));
        dispatch(setUpdatingJson(false));
        applyClippingToCanvas(
          canvas,
          catalogData,
          undefined,
          angle,
          currentColor.current === ''
            ? selectedVariant?.colorHex
            : currentColor.current,
        );

        if (undoAndRedoLoadingRef.current) {
          undoAndRedoLoadingRef.current = false;
          await debouncedUpdateBlob(
            defaultEditorData,
            variantEditorData,
            canvas,
          );
        }
        // generating mockups section
        if (updatedVariant && !angleChange) {
          await generateMockupsForEachColorAndAngle(
            canvas,
            loadMockupFromApi,
            loadMockupFromApi
              ? productInfo
                ? productVariants
                : []
              : [updatedVariant ?? selectedVariant],
            json,
          );
          setUpdating(false);
        }
        dispatch(setUpdatingJson(false));
      });

      if (loadingRef.current === true) {
        loadingRef.current = false;
      }
    }

    if (updateUndoRedoState) {
      updateUndoRedoState();
    }
  };

  useEffect(() => {
    const fetchOrderData: any = async (orderId: any) => {
      const res = await getOrderData({ orderId });
      if (res.isError) {
        navigate('/');
        return;
      }
      setOrderInfoForPersonalization(res.data.orderInfo);
      return res.data.editorData;
    };
    if (
      !document.getElementsByClassName('canvas-container')[0] &&
      !isFetchingCatalogInfo &&
      !updating
    ) {
      if (
        location.pathname.includes('personalize') &&
        orderInfoForPersonalization === ''
      ) {
        const URL = location.search;
        const searchParams = new URLSearchParams(URL);
        const orderId = searchParams.get('orderId');
        if (orderId === null) {
          toast.error('Invalid URL');
          navigate('/');
          return;
        }
        fetchOrderData(orderId).then((_jsons: any) => {});
      }
      //
      else {
        initializeCanvasWithJson(null, drawingBoard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFetchingCatalogInfo]);

  const updateObjectInStoreAfterUpdate = (e: IEvent) => {
    dispatch(updateObject(e.target?.toDatalessObject(fabricFieldsToKeep)));
  };

  const updateStoreDataTosync = (e: IEvent) => {
    const ele = e.target;
    setSelectedElementProprties({
      top: ele?.top ?? 0,
      left: ele?.left ?? 0,
      type: ele?.type,
      id: ele?.toDatalessObject(fabricFieldsToKeep).id,
    });
    updateObjectInStoreAfterUpdate(e);
  };

  // mockups-2

  const updateBlob = async (
    defaultData: any,
    variantEditorData: any,
    signal?: AbortSignal,
    newDrawingBoard?: Canvas,
  ) => {
    try {
      dispatch(setIsBlobUpdating(true));
      const copyOfBoard = newDrawingBoard ?? drawingBoard;
      const dataToExtract: any = copyOfBoard?.toJSON(fabricFieldsToKeep);
      const finalObjects = {
        ...dataToExtract,
        objects: dataToExtract.objects.filter(
          (obj: JSONObject) =>
            obj.type === ObjectTypes.image || obj.type === ObjectTypes.text,
        ),
      };
      const jsonData =
        finalObjects.objects.length === 0 ? null : JSON.stringify(finalObjects);
      const index = variantEditorData.findIndex(
        (e: { id: string | undefined }) =>
          e.id === selectedVariant?.id ||
          ((selectedVariant?.connectedVariants || []) as any)
            .map((a: { id: any }) => a.id)
            .includes(e.id),
      );

      const currentVariant = variantEditorData[index];
      const imageData: any = await getCurrentViewImage(
        copyOfBoard,
        catalogData,
        selectedAngle.angle,
        null,
        currentProductType,
        base64CacheRef.current,
        printAreas,
      );
      if (currentVariant && currentVariant?.variantSpecificDesign) {
        const newVariantData = variantEditorData.map((variant: any) => {
          if (variant.id === currentVariant.id) {
            return {
              ...variant,
              [selectedAngle.angle]: jsonData,
              [selectedAngle.angle + 'image']: imageData.blob,
            };
          }
          return variant;
        });
        setVariantEditorData(newVariantData);
      } else {
        const newDefaultData = {
          ...defaultData,
          [selectedAngle.angle + 'image']: imageData.blob,
          [selectedAngle.angle]: jsonData,
        };
        setDefaultEditorData(newDefaultData);
      }

      if (signal?.aborted) {
        // if the signal is aborted then return from the function
        return;
      }
      if (currentProductType !== ProductType.Mug) {
        setDataToGenerateAngleImages([
          {
            imageData: imageData.blob,
            catalogData,
            variant: selectedVariant,
            pixiApp,
            angle: selectedAngle.angle,
          },
        ]);
      }
    } catch (error) {
      console.error(error, 'error');
    } finally {
      setIsAnglesLoading(false);
      dispatch(setIsBlobUpdating(false));
    }
  };

  const showGridLines = (e: IEvent) => {
    const elementProperties = e.target?.getBoundingRect();

    const clipPath = drawingBoard?.clipPath;
    const drawingboxWidth = drawingBoard?.clipPath
      ? drawingBoard?.clipPath?.width
      : drawingBoard?.width;
    const drawingboxHeight = drawingBoard?.clipPath
      ? drawingBoard?.clipPath?.height
      : drawingBoard?.height;

    if (elementProperties && drawingboxWidth && drawingboxHeight) {
      const {
        width: eleWidth,
        height: eleHeight,
        left: eleLeft,
        top: eleTop,
      } = elementProperties;
      const elementLeftCenterPoint = eleLeft + eleWidth / 2;
      const elementTopCenterPoint = eleTop + eleHeight / 2;
      const leftCenterPoint = drawingBoard?.clipPath?.left
        ? drawingBoard?.clipPath?.left + drawingboxWidth / 2
        : drawingboxWidth / 2;
      const topCenerPoint = drawingBoard?.clipPath?.top
        ? drawingBoard?.clipPath?.top + drawingboxHeight / 2
        : drawingboxHeight / 2;
      const isElementNearToVerticalLine =
        elementLeftCenterPoint < leftCenterPoint + 7 &&
        elementLeftCenterPoint > leftCenterPoint - 7;
      const isElementNearToHorizontalLine =
        elementTopCenterPoint < topCenerPoint + 7 &&
        elementTopCenterPoint > topCenerPoint - 7;
      const lines = drawingBoard?.getObjects('line');
      const horizontalLine = lines?.filter(
        (line) => line.name === 'horizontalLine',
      );
      const verticallLine = lines?.filter(
        (line) => line.name === 'verticalLine',
      );
      if (horizontalLine && verticallLine) {
        if (clipPath) {
          horizontalLine[0]?.set({
            visible: isElementNearToHorizontalLine,
            top: (clipPath?.top ?? 0) + (clipPath?.height ?? 0) / 2,
          });
          verticallLine[0]?.set({
            visible: isElementNearToVerticalLine,
            left: (clipPath?.left ?? 0) + (clipPath?.width ?? 0) / 2,
          });
        } else {
          horizontalLine[0]?.set({ visible: isElementNearToHorizontalLine });
          verticallLine[0]?.set({ visible: isElementNearToVerticalLine });
        }
        drawingBoard?.bringToFront(horizontalLine[0]);
        drawingBoard?.bringToFront(verticallLine[0]);
      }

      if (
        isElementNearToVerticalLine &&
        (e.target?.left ?? 0) < leftCenterPoint + 7 &&
        (e.target?.left ?? 0) > leftCenterPoint - 7
      ) {
        e.target?.set({ left: leftCenterPoint });
      } else {
        e.target?.set({ left: e.target.left });
      }
      if (
        isElementNearToHorizontalLine &&
        (e.target?.top ?? 0) < topCenerPoint + 7 &&
        (e.target?.top ?? 0) > topCenerPoint - 7
      ) {
        e.target?.set({ top: topCenerPoint });
      } else {
        e.target?.set({ top: e.target.top });
      }
      drawingBoard?.requestRenderAll();
    }
  };

  useEffect(() => {
    if (drawingBoard) {
      drawingBoard?.on('object:removed', (e: IEvent) => {
        const element = e.target?.toObject();
        dispatch(deleteObject(element.id));
        setDesignChanged(true);
      });

      drawingBoard?.on('object:modified', (e: IEvent) => {
        setDesignChanged(true);
        updateUndoRedoCanvasState();
      });

      drawingBoard?.on('object:added', (e: IEvent) => {
        setDesignChanged(true);
        if (!undoAndRedoLoadingRef.current) {
          if (drawingBoard?.clipPath) {
            let areaRemainingLeft =
              (drawingBoard.clipPath.width ?? 0) -
              (e.target?.getScaledWidth() ?? 0);
            areaRemainingLeft = areaRemainingLeft / 2;
            let areaRemainingTop =
              (drawingBoard.clipPath.height ?? 0) -
              (e.target?.getScaledHeight() ?? 0);
            areaRemainingTop = areaRemainingTop / 2;
            e.target?.set({
              left:
                (drawingBoard?.clipPath?.left ?? 0) +
                e.target.getScaledWidth() / 2 +
                areaRemainingLeft,
              top:
                (drawingBoard?.clipPath?.top ?? 0) +
                e.target.getScaledHeight() / 2 +
                areaRemainingTop,
            });
          }
        }
        setAddImageLoader(false);
        updateUndoRedoCanvasState();
        dispatch(addObject(e.target?.toDatalessObject(fabricFieldsToKeep)));
        setSelectedElementProprties({
          top: e.target?.toObject()?.top ?? 0,
          left: e.target?.toObject()?.left ?? 0,
          type: e.target?.toObject()?.type,
          id: e.target?.toObject()?.id,
        });
      });
    }
  }, [drawingBoard, loadingRef]);

  useEffect(() => {
    drawingBoard?.on('object:added', (e: IEvent) => {
      setDesignChanged(true);

      if (!undoAndRedoLoadingRef.current) {
        if (!loadingRef.current && !undoAndRedoLoadingRef.current)
          updateBlob(
            stateValuesRef.current.defaultEditorData,
            stateValuesRef.current.variantEditorData,
          );
      }
    });

    drawingBoard?.on('object:removed', (e: IEvent) => {
      setDesignChanged(true);

      if (!loadingRef.current && !undoAndRedoLoadingRef.current)
        updateBlob(
          stateValuesRef.current.defaultEditorData,
          stateValuesRef.current.variantEditorData,
        );
    });
  }, [drawingBoard]);

  useEffect(() => {
    if (drawingBoard) {
      drawingBoard.on('text:changed', (e: IEvent) => {
        updateStoreDataTosync(e);
        const lines = drawingBoard?.getObjects('line');
        const horizontalLine = lines?.filter(
          (line) => line.name === 'horizontalLine',
        );
        const verticallLine = lines?.filter(
          (line) => line.name === 'verticalLine',
        );
        horizontalLine[0].set({ visible: false });
        verticallLine[0].set({ visible: false });
        if (!loadingRef.current && !undoAndRedoLoadingRef.current)
          debouncedUpdateBlob(
            stateValuesRef.current.defaultEditorData,
            stateValuesRef.current.variantEditorData,
          );
      });

      drawingBoard.on('selection:created', (e: IEvent) => {
        handleCanvasElementSelection(e);
      });

      drawingBoard.on('selection:updated', (e: IEvent) => {
        e.deselected?.[0].set({
          backgroundColor: 'transparent',
        });
        drawingBoard.renderAll();
        handleCanvasElementSelection(e);
      });

      drawingBoard.on('selection:cleared', (e) => {
        e.deselected?.[0].set({
          backgroundColor: 'transparent',
        });
        drawingBoard.renderAll();
        setSelectedElementProprties({ type: '', top: 0, left: 0, id: '' });
      });

      drawingBoard.on('object:moving', (e: IEvent) => {
        setMoving(true);
        updateStoreDataTosync(e);

        showGridLines(e);
        var boundary = {
          top: 0,
          left: 0,
          bottom: drawingBoard.getHeight(),
          right: drawingBoard.getWidth(),
        };

        var obj: any = e.target;

        // Check if the object is going out of bounds
        var objTop: any = obj?.top;
        var objLeft: any = obj?.left;
        var objBottom: any = objTop ?? 0 + obj.height;
        var objRight: any = objLeft ?? 0 + obj.width;

        // Adjust object position if it goes out of bounds
        if (
          currentProductType === ProductType.ApparelHeavy ||
          currentProductType === ProductType.ApparelLight ||
          currentProductType === ProductType.ToteBag
        ) {
          if (objTop < boundary.top) {
            obj?.set({ top: boundary.top });
          }
          if (objLeft < boundary.left) {
            obj?.set({ left: boundary.left });
          }
          if (objBottom > boundary.bottom) {
            obj?.set({ top: boundary.bottom - obj.height });
          }
          if (objRight > boundary.right) {
            obj?.set({ left: boundary.right - obj.width || 0 });
          }
        }
      });

      drawingBoard.on('mouse:up', (e: IEvent) => {
        const ele = e.target;
        setMoving(false);
        if (ele && ele !== undefined) {
          setSelectedElementProprties({
            top: ele.top ?? 0,
            left: ele.left ?? 0,
            type: ele.type,
            id: ele.toDatalessObject(fabricFieldsToKeep).id,
          });
        }
      });

      drawingBoard.on('object:modified', (e: IEvent) => {
        setDesignChanged(true);
        if (!loadingRef.current && !undoAndRedoLoadingRef.current)
          debouncedUpdateBlob(
            stateValuesRef.current.defaultEditorData,
            stateValuesRef.current.variantEditorData,
          );
        const lines = drawingBoard?.getObjects('line');
        const horizontalLine = lines?.filter(
          (line) => line.name === 'horizontalLine',
        );
        const verticallLine = lines?.filter(
          (line) => line.name === 'verticalLine',
        );
        horizontalLine.forEach((ele) => ele.set({ visible: false }));
        verticallLine.forEach((ele) => ele.set({ visible: false }));
        dispatch(updateObject(e.target?.toDatalessObject(fabricFieldsToKeep)));
        drawingBoard?.requestRenderAll();
      });

      drawingBoard.on('object:skewing', (e: IEvent) => {
        updateStoreDataTosync(e);
      });

      drawingBoard.on('object:scaling', (e: IEvent) => {
        updateStoreDataTosync(e);
        // showGridLines(e);
      });

      drawingBoard.on('object:rotating', (e: IEvent) => {
        updateStoreDataTosync(e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingBoard, loadingRef]);

  const handleCanvasElementSelection = (e: IEvent) => {
    const ele = e.selected?.[0].toObject();
    if (ele.personalizationData) {
      e.selected?.[0].set({
        backgroundColor: 'white',
      });
    }
    setSelectedElementProprties({
      top: ele.top,
      left: ele.left - ele.width * ele.scaleX,
      type: ele.type,
      id: ele.id,
    });
    drawingBoard?.renderAll();
  };

  const handleVariantChange = async (
    updatedVariant: VariantsV2,
    trigger?: boolean,
  ) => {
    if (updatedVariant.id !== selectedVariant?.id || trigger) {
      if (currentProductType === ProductType.Ornament) {
        currentColor.current = updatedVariant.colorHex;
      }
      setSelectedVariant(updatedVariant);
      setUpdating(true);

      if (currentProductType === ProductType.Ornament) {
        setSelectedAngle({
          ...selectedAngle,
          image:
            (catalogData?.previewBoundaryBox[
              'normal' + selectedAngle.angle + 'MainImageUrl'
            ] as string) ?? '',
          originalImage:
            (catalogData?.previewBoundaryBox[
              'normal' + selectedAngle.angle + 'MainImageUrl'
            ] as string) ?? '',
        });
      } else {
        setSelectedAngle({
          ...selectedAngle,
          image: updatedVariant.images[selectedAngle.angle],
          originalImage: updatedVariant.images[selectedAngle.angle],
        });
      }
      const jsonData = getCurrentAngleJson(
        variantEditorData,
        updatedVariant,
        selectedAngle.angle,
        defaultEditorData,
      );

      loadingRef.current = true;
      initializeFabricWithNewCanvas(
        catalogData,
        initializeCanvasWithJson,
        jsonData,
        false,
        printAreas,
        updatedVariant,
        undefined,
        selectedAngle.angle,
        undefined,
        currentProductType === ProductType.Ornament
          ? updatedVariant.colorHex
          : undefined,
      );
    }
  };

  const handleAngleChange = async (newAngle: string, newImage: string) => {
    const { angle } = selectedAngle;
    if (newAngle === angle) {
      return;
    }
    setUpdating(true);
    dispatch(setUpdatingJson(true));
    const jsonData = getCurrentAngleJson(
      variantEditorData,
      selectedVariant,
      newAngle,
      defaultEditorData,
    );
    loadingRef.current = true;
    initializeFabricWithNewCanvas(
      catalogData,
      initializeCanvasWithJson,
      jsonData,
      false,
      printAreas,
      selectedVariant,
      undefined,
      newAngle,
      true,
    );
    dispatch(
      setObjects(
        jsonData === null || jsonData === ''
          ? { objects: [] }
          : JSON.parse(jsonData),
      ),
    );
    setSelectedAngle({
      image: newImage,
      angle: newAngle,
      originalImage: selectedVariant?.images[newAngle],
    });
    setUpdating(false);
  };
  // mockups-3
  const manageSpecificationDesign = async (status: boolean, id: string) => {
    const copyVariant = [...variantEditorData];
    const index = copyVariant.findIndex((variant) => variant.id === id);
    const element = copyVariant[index];
    element.variantSpecificDesign = status;
    if (status === false) {
      element.front = null;
      element.back = null;
      delete element.backimage;
      delete element.frontimage;
      handleVariantChange(
        selectedColors.find(
          (v: any) =>
            v.id === element.id ||
            v.connectedVariants
              .map((a: { id: any }) => a.id)
              .includes(element.id),
        ),
        true,
      );
    } else {
      element.front = defaultEditorData.front;
      element.back = defaultEditorData.back;
      element.backimage = defaultEditorData.backimage;
      element.frontimage = defaultEditorData.frontimage;
    }
    copyVariant[index] = element;
    setVariantEditorData(copyVariant);
  };

  /** pixi setup */
  useEffect(() => {
    if (!catalogData?.title.includes('Mug')) {
      if (!pixiApp) {
        const app = new PIXI.Application({
          width: 200,
          height: 250,
          backgroundColor: 0xffffff,
        });

        setPixiApp(app);
      }
      return () => {
        if (pixiApp) {
          pixiApp.destroy(true);
        }
      };
    }
  }, []);

  const debouncedUpdateBlob = debounce(
    async (defaultData: any, editorData: any, newDrawingBoard?: Canvas) => {
      // Cancel the ongoing updateBlob function if it exists
      if (controllerRef.current) {
        controllerRef.current.abort();
        debouncedUpdateBlob.cancel();
        controllerRef.current = null;
      }

      // Creating new AbortController
      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;

      try {
        // Call updateBlob with the signal and the latest arguments
        dispatch(setIsBlobUpdating(true));
        await updateBlob(defaultData, editorData, signal, newDrawingBoard);
        dispatch(setIsBlobUpdating(false));
      } finally {
        // Clear the controller after the task completes or is canceled
        if (!isKeyLongPressed.current) {
          controllerRef.current = null;
        } else {
        }
      }
    },
    500,
  );

  const UndoRedo = () => {
    return (
      <UndoRedoHandler
        catalogData={catalogData}
        setConfigForUndoRedo={setConfigForUndoRedo}
        configForUndoRedo={configForUndoRedo}
        drawingBoard={drawingBoard}
        initializeCanvasWithJson={initializeCanvasWithJson}
        undoAndRedoLoadingRef={undoAndRedoLoadingRef}
        angle={selectedAngle.angle}
        productInfo={productInfo}
        variantSpecificData={variantEditorData.find(
          (variant) => variant.id === selectedVariant?.id,
        )}
        disableUndoRedo={isAnglesLoading}
        isKeyLongPressed={isKeyLongPressed}
        selectedColor={
          currentProductType === ProductType.Ornament
            ? selectedVariant?.colorHex
            : undefined
        }
        selectedVariant={selectedVariant}
        printAreas={printAreas}
      />
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      toolbarRef.current &&
      sidebarRef.current &&
      (!mugCanvasRef.current ||
        !mugCanvasRef.current.contains(event.target as Node)) &&
      (!ornamentCanvasRef.current ||
        !ornamentCanvasRef.current.contains(event.target as Node)) &&
      (!apparelCanvasRef.current ||
        !apparelCanvasRef.current.contains(event.target as Node)) &&
      (!toteBagCanvasRef.current ||
        !toteBagCanvasRef.current.contains(event.target as Node)) &&
      !toolbarRef.current.contains(event.target as Node) &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      drawingBoard?.discardActiveObject();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const floatingToolbar = () => {
    return (
      selectedElementProperties.id &&
      !moving &&
      !location.pathname.includes('personalize') &&
      !updating && (
        <div ref={toolbarRef}>
          <ToolBar
            top={selectedElementProperties.top}
            left={selectedElementProperties.left}
            type={selectedElementProperties.type}
            drawingBoard={drawingBoard}
            id={selectedElementProperties.id}
          />
        </div>
      )
    );
  };
  const [isDragging, setIsDragging] = useState(false);
  const printAreaDimension = useMemo(() => {
    if (selectedAngle && printAreas?.sides) {
      return printAreas?.sides.find((p) => p.side === selectedAngle.angle)
        ?.designFileDimensionsInPX;
    }
  }, [printAreas?.sides, selectedAngle]);
  return (
    <>
      {isFetchingCatalogInfo || isFetching ? (
        <Loader />
      ) : (
        <>
          {(isProductInfoFetching || isUploading) && <Loader />}
          {catalogData && isSuccess && (
            <Section
              isTablet={isTablet}
              personalization={isSellerEditor}
              isSidebarExpanded={isSidebarExpanded}
            >
              <Dropzone
                maxSize={MAX_DESIGN_FILE_SIZE}
                multiple={false}
                maxFiles={1}
                ref={dropzoneRef}
                noClick={true}
                onDropRejected={onDropRejectedHandler}
                disabled={isUploading || currentTab === 'myLibrary'}
                onDrop={(acceptedFiles, fileRejections) => {
                  setIsDragging(false);
                  if (fileRejections.length > 0) return;
                  uploadToGallery(
                    acceptedFiles,
                    addToGallery,
                    drawingBoard,
                    files,
                    currentProductType,
                  );
                }}
                accept={{
                  'image/png': ['.png'],
                  'image/jpg': ['.jpg'],
                  'image/jpeg': ['.jpeg'],
                  'image/svg': ['.svg'],
                }}
                onDragEnter={() => setIsDragging(true)}
                onDragLeave={() => setIsDragging(false)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={{}}>
                    <>
                      <MainDrawingArea isTablet={isTablet}>
                        {isDragging && <DropzoneOverlay />}
                        {!isSellerEditor && (
                          <TopBar
                            title={catalogData.title}
                            onSave={() => setCallBuildImages(true)}
                            originalData={updatedEditorData}
                            updatedData={
                              defaultEditorData &&
                              variantEditorData && {
                                defaultEditorData: defaultEditorData,
                                variantEditorData: variantEditorData,
                                selectedColors: selectedColors,
                              }
                            }
                            currentPage={'editor'}
                            printProviderInfo={data.printProviderInfo}
                            isTablet={isTablet}
                          />
                        )}
                        <div style={{ position: 'relative' }}>
                          {!isTablet && (
                            <img
                              onClick={() => setKeyboardModal(true)}
                              src={QuestionMarkIcon}
                              alt="keyboard shortcut"
                              className="pointer-cursor keyboard-button"
                              height={24}
                              width={24}
                            />
                          )}
                          {currentProductType === ProductType.ApparelHeavy ||
                          currentProductType === ProductType.ApparelLight ||
                          currentProductType === ProductType.ToteBag ? (
                            <ApparelEditor
                              preloadedImages={preloadedImages}
                              addImageLoader={addImageLoader}
                              drawingBoard={drawingBoard}
                              isAnglesLoading={isAnglesLoading}
                              isTablet={isTablet}
                              isKeyLongPressed={isKeyLongPressed.current}
                              selectedAngle={selectedAngle}
                              dataToGenerateAngleImage={
                                dataToGenerateAngleImage
                              }
                              selectedVariant={selectedVariant}
                              handleAngleChange={(angle, src) =>
                                handleAngleChange(angle, src)
                              }
                              files={files}
                              addToGallery={addToGallery}
                              ToolBar={floatingToolbar()}
                              UndoRedoComponent={UndoRedo()}
                              printArea={printAreas}
                              isProductInfoFetching={isProductInfoFetching}
                              currentProductType={currentProductType}
                              setAddImageLoader={setAddImageLoader}
                              apparelCanvasRef={apparelCanvasRef}
                              dropzoneRef={dropzoneRef}
                              printAreaDimension={printAreaDimension}
                            />
                          ) : currentProductType === ProductType.Mug ? (
                            <MugsEditor
                              isTablet={isTablet}
                              files={files}
                              addToGallery={addToGallery}
                              drawingBoard={drawingBoard}
                              ToolBar={floatingToolbar()}
                              UndoRedoComponent={UndoRedo()}
                              image={getVariantSpecificImageData(
                                selectedVariant,
                              )}
                              selectedColor={selectedVariant ?? ''}
                              isColoredMug={isColoredMug ?? true}
                              isBlackMug={isBlackMug}
                              addImageLoader={addImageLoader}
                              mugCanvasRef={mugCanvasRef}
                              model={catalogData.model}
                              dropzoneRef={dropzoneRef}
                              printAreaDimension={printAreaDimension}
                              printAreas={printAreas}
                            />
                          ) : (
                            <>
                              <OrnamentEditor
                                preloadedImages={preloadedImages}
                                isTablet={isTablet}
                                files={files}
                                addToGallery={addToGallery}
                                drawingBoard={drawingBoard}
                                ToolBar={floatingToolbar()}
                                UndoRedoComponent={UndoRedo()}
                                title={catalogData.title}
                                dataToGenerateAngleImage={
                                  dataToGenerateAngleImage
                                }
                                selectedVariant={selectedVariant}
                                selectedAngle={selectedAngle}
                                printArea={printAreas}
                                handleAngleChange={(angle, src) =>
                                  handleAngleChange(angle, src)
                                }
                                addImageLoader={addImageLoader}
                                ornamentCanvasRef={ornamentCanvasRef}
                                previewBoundaryBox={
                                  catalogData.previewBoundaryBox
                                }
                                isAnglesLoading={isAnglesLoading}
                                dropzoneRef={dropzoneRef}
                                printAreaDimension={printAreaDimension}
                              />
                            </>
                          )}
                        </div>
                      </MainDrawingArea>
                    </>
                  </div>

                )}
              </Dropzone>
              {/*Right Sidebar starts */}

              <SideBar
                className="show-no-scrollbar sidebar"
                style={{ position: 'relative' }}
                isTablet={isTablet}
                isSidebarExpanded={isSidebarExpanded}
                ref={sidebarRef}
              >
                <RightSideBar
                  selectedElementId={selectedElementProperties.id ?? ''}
                  drawingBoard={drawingBoard}
                  selectedVariant={selectedVariant}
                  catalogVariants={catalogVariants}
                  setSelectedVariant={(variant) => handleVariantChange(variant)}
                  catalogProductId={catalogProductId}
                  selectedAngle={selectedAngle.angle}
                  anglesData={variantEditorData}
                  manageSpecificationDesign={manageSpecificationDesign}
                  defaultEditorData={defaultEditorData}
                  modelNumber={
                    catalogData?.model || getOrnamentsModel(catalogData?._id)
                  }
                  isTablet={isTablet}
                  productName={catalogData.title}
                  setAddImageLoader={setAddImageLoader}
                  undoAndRedoLoadingRef={undoAndRedoLoadingRef}
                  isSellerEditor={isSellerEditor}
                  instructions={instructions}
                  productIdFromPersonalization={productIdFromPersonalization}
                  transactionId={transactionId}
                  setShowPersonalizeEditorModal={setShowPersonalizeEditorModal}
                  editorDataFromProps={editorDataPropsJson}
                  printProviderId={ordersPrintProviderId ?? printProviderId}
                  currentProductType={currentProductType}
                  printSides={printSides}
                  printAreaDimensions={printAreas?.sides}
                  dropzoneRef={dropzoneRef}
                  callBuildImages={callBuildImages}
                  setCallBuildImages={setCallBuildImages}
                  extraSideCost={catalogData.extraSideCost}
                  designChanged={designChanged}
                />
              </SideBar>

              <KeyboardShortcutModal
                keyboardModal={keyboardModal}
                setKeyboardModal={setKeyboardModal}
              />
              {/* Right Sidebar ends */}
            </Section>
          )}
        </>
      )}
    </>
  );
};

export default Editor;

const Section = styled.div<{
  isTablet: boolean;
  personalization: boolean;
  isSidebarExpanded: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => props.isTablet && 'column'};
  height: ${(props) => (props.personalization ? '90vh' : ' 100vh')};
  overflow: hidden;
  > div:first-child {
    width: ${(props) =>
      props.isTablet ? '100%' : props.isSidebarExpanded ? '80%' : '75%'};
  }

  .angle-image {
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > :first-child {
      background: white;
      border-radius: 6px;
    }
    &.selected {
      > :first-child {
        border: 2px solid ${colors.blue[700]};
      }
    }
    .angles-loader {
      position: absolute;
      background-color: transparent;
      width: 100px;
    }
    img {
      width: ${(props) => (props.isTablet ? '140px' : '164px')};
      height: ${(props) => (props.isTablet ? '140px' : '164px')};
      object-fit: contain;
    }
  }
  .angles-listing {
    ${(props) =>
      props.isTablet &&
      css`
        display: flex;
        justify-content: center;
      `}

    > div > :first-child {
      cursor: pointer;
    }
  }
  .mug-editor-wrapper {
    min-height: 95vh;
    overflow: hidden;
  }
`;

const MainDrawingArea = styled.div<{ isTablet: boolean }>`
  height: ${(props) => (props.isTablet ? 'calc(100vh - 70px)' : '100vh')};
  background-color: ${colors.grey[100]};
  display: flex;
  flex-direction: column;
  .keyboard-button {
    background: white;
    border-radius: 6px;
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    position: absolute;
    right: 0;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08),
      0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  }
  .canvas {
    position: absolute;
    cursor: pointer;
    p {
      color: ${colors.grey[600]};
      text-align: center;
      font-size: 16px;
    }
    span {
      color: ${colors.grey[600]};
    }
    .saturation-white > div:nth-of-type(2) {
      cursor: grab !important;
    }
  }
`;

const SideBar = styled.div<{ isTablet: boolean; isSidebarExpanded: boolean }>`
  background-color: ${colors.white};
  width: ${(props) =>
    props.isTablet ? '100%' : props.isSidebarExpanded ? '30%' : '25%'};
  padding: 10px;
  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: ${colors.white};
    overflow: hidden;
  }
`;
